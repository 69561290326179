<template>
    <div id="profile" class="bg-gray-relative-100">
        <div class="bg-body">
            <SubHeader title="프로필" :setting="true"></SubHeader>
        </div>
        <div class="avatar">
            <div class="w-100 position-relative" >
                <ul class="mypage_cate bg-body d-flex py-3 shadow">
                    <li @click="category=1">내정보</li>
                    <li @click="category=2" class="border-start">비밀번호변경</li>
                </ul>
            </div>
        </div>
        <div class="p-3 pt-4">
            <div class="bg-body rounded-4 shadow-300 p-3" v-show="category===1">
                <h6 class="py-3 px-2 mb-3 border-bottom">내 정보</h6>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">이름</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body  text-body border-gray-relative-200" v-model="name" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">부서명</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body text-body border-gray-relative-200" v-model="department" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">직위</label>
                <div class="input-group mb-3">
                    <input type="text"  class="form-control thick bg-body text-body border-gray-relative-200" v-model="rank" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">전화번호</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body text-body border-gray-relative-200" v-model="tel" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">긴급연락처</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body text-body border-gray-relative-200" v-model="e_tel" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">팩스번호</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body text-body border-gray-relative-200" v-model="fax" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">이메일</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body  text-body border-gray-relative-200" v-model="email" disabled>
                </div>
            </div>
            
            
            <div class="bg-body rounded-4 shadow-300 p-3" v-show="category===2">
                <label for="password" class="form-label fs-px-12 mb-1 px-2 fw-bold">비밀번호 변경</label>
                <div class="input-group mb-1">
                    <input type="password" id="password" class="form-control thick text-body border-gray-relative-200" placeholder="현재 비밀번호" v-model="old_password" autocomplete="none">
                </div>
                <div class="input-group mb-1">
                    <input type="password" id="new_password" class="form-control thick text-body border-gray-relative-200" placeholder="신규 비밀번호" v-model="new_password" autocomplete="none">
                </div>
                <div class="input-group mb-1">
                    <input type="password" id="new_password2" class="form-control thick text-body border-gray-relative-200" placeholder="신규 비밀번호 확인" v-model="new_password_confirm" autocomplete="none">
                </div>
                <button class="btn thick bg-secondary text-white mt-2 w-100" @click="ChangePassword()">변경</button>
            </div>

        </div>
        <div class="px-4 py-3 text-gray-relative-500 fs-px-13 text-end">
            <span @click="LogOut()">로그아웃</span>
            <!-- <span class="text-gray-relative-300 mx-3 text-opacity-50">|</span>
            <router-link to="/opt-out">회원탈퇴</router-link> -->
        </div>
    </div>
</template>
<script>
const CryptoJS = require("crypto-js");
import SubHeader from '@/components/common/SubHeader.vue'
export default {
    components: {
        SubHeader
    },
    data() {
        return {
            category:1,
            shop_image : '',
            old_password :'',
            new_password:'',
            new_password_confirm:'',
            name : '',
            department : '',
            rank : '',
            tel : '',
            e_tel :'',
            fax : '',
            email : '',
            
        }
    },
    mounted() {
        // 임시 데이터
        this.GetMyInfo();
    },
    methods: {
        ChangePassword(){
            const old_password  = this.old_password;
            const new_password = this.new_password;
            const new_password_confirm = this.new_password_confirm;

            if(old_password ==''){
                
                this.$alert("현재 비밀번호를 입력해주세요.");
                return false;
            }

            if(new_password ==''){
                this.$alert("신규 비밀번호를 입력해주세요.");
                return false;
            }

            if(new_password !=new_password_confirm){
                this.$alert("변경할 비밀번호를 확인해주세요.");
                return false;
            }


            const body = {old_password,new_password}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/myinfo/ChangePassword', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){

                            this.$alert("비밀번호를 변경하였습니다.<br>확인을 위해서 재접속해주세요.").then(
                                ()=>{
                                    this.LogOut();
                                }
                            )
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
        GetMyInfo(){
            const body = {}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/myinfo/GetMyInfo', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  

                            const info = body.info;
                            
                            this.name = info.name;
                            this.department = info.department;
                            this.rank = info.rank;
                            this.tel = info.tel;
                            this.e_tel = info.e_tel;
                            this.fax = info.fax;
                            this.email = info.email;

                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
        LogOut() {
            this.$store.dispatch('SETLOGOUT').then(
                ()=>{
                    window.location.href="/signin";
                }
            )
        }
    }
}
</script>
<style lang="scss" scoped>
    .avatar>div {
        width: 100%;
        height: 50px;
        background-size: cover;
    }
    .mypage_cate{
        width: 80%;
        max-width: 260px;
        text-align: center;
        font-size: 13px;
        border-radius: 10px;
        position:absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        li{
            width: 50%;
        }
    }
</style>